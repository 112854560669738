import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';
import { Quote } from 'stile-shared/src/components/2020/common/Quote';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function OurWhyPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/our-why/cover_our_why.png"
            alt="A student works quietly on his laptop in a science lab"
          />
        }
      />

      <TextBlock title="We want our young citizens to graduate from school scientifically literate and ready to tackle tomorrow’s problems.">
        <P>
          In a mere 500 years – a tiny fraction of humanity’s history – science, and the
          technological advances that stemmed from it, has completely transformed every part of our
          lives. The scale of humanity’s scientific transformation in such a short period is so
          immense it’s hard to grasp.
        </P>

        <P>
          Given such incredible advancement, it’s tempting to think that science education must be
          in pretty good shape. Sadly, it isn’t. We could talk about falling PISA rankings, or
          declining STEM enrolments, or the surveys suggesting that students increasingly view
          science as irrelevant to their lives. But instead, and perhaps more importantly, let’s
          consider the world to which our students will graduate.
        </P>

        <P>A world of “fake news“ and “alternative facts”.</P>

        <P> A world where automation is rapidly and radically reshaping the workforce.</P>

        <P> A world where science and technology is increasingly pervasive in our lives.</P>
        <P>
          For our students to prosper, we must ensure they leave school confident to tackle
          tomorrow’s problems. Confident to participate in public discourse around the ethics of
          scientific issues such as artificial intelligence, vaccination or genetically modified
          food. Confident in their ability to think through issues for themselves using the tools
          science provides, and to continually learn and adapt through a multitude of careers.
        </P>

        <P>
          Stile is rethinking STEM education in an effort to ensure students graduate as informed,
          reflective citizens. Citizens equipped to create a better future for themselves, and for
          others.
        </P>
      </TextBlock>

      <TextBlock>
        <Quote
          text="The solutions to political and ethical dilemmas involving science and technology cannot be the subject of informed debate unless young people possess certain scientific awareness."
          credit="PISA 2015 Assessment and Analytical Framework"
        />
      </TextBlock>

      <NextPageBlock path="/who-we-are/our-principles/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default OurWhyPage;
